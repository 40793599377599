import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Header({ logo }) {
  return (
    <div>
      <div className="flex items-center justify-between px-5 py-5 mt-container lg:px-0">
        <Link to="/">
          {logo ? (
            <GatsbyImage image={logo} />
          ) : (
            <div className="inline-flex items-center self-start transition-opacity duration-300 hover:opacity-80">
              <div className="p-2 bg-gradient w-[40px] h-[40px] flex justify-center items-center rounded-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
              </div>
              <h1 className="ml-2 text-2xl font-bold tracking-wide txt-gradient">
                jpegtowebpfree
              </h1>
            </div>
          )}
        </Link>
        <div className="space-x-3 ">
          <a
            href="http://www.civsav.com"
            target="_blank"
            className="text-gray-600 transition-colors hover:text-gray-800"
            rel="noreferrer"
          >
            Visit Us
          </a>
        </div>
      </div>
    </div>
  );
}
